export const AUTH_ERROR_CODES = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  ACCOUNT_DOESNT_EXIST: 'ACCOUNT_DOESNT_EXIST',
  ADMIN_USER: 'ADMIN_USER',
  ACCESS_BLOCKED: 'ACCESS_BLOCKED',
} as const

export const INVALID_CREDENTIALS = {
  code: AUTH_ERROR_CODES.INVALID_CREDENTIALS,
  error: 'Invalid credentials',
} as const

export const ACCOUNT_DOESNT_EXIST = {
  code: AUTH_ERROR_CODES.ACCOUNT_DOESNT_EXIST,
  error: 'No existing account found for this email',
} as const

export const ADMIN_USER = {
  code: AUTH_ERROR_CODES.ADMIN_USER,
  error: 'This user is an admin. Try logging into backstage?',
}

export const ACCESS_BLOCKED = {
  code: AUTH_ERROR_CODES.ACCESS_BLOCKED,
  error: 'Your account is on hold at this time. Please contact support at support@trykeep.com for more information.',
}

export type AUTH_CODES = keyof typeof AUTH_ERROR_CODES
