import { BankingDataProvider } from '@prisma/client'
import cuid from 'cuid'
import { PlaidItem } from 'db-schema'
import { isDefined } from 'utils'

import { ApplicationRelevantPerson, ApplicationV2, OnboardingStepV2, PersonParticipation } from '@/gql'
import { ApplicationUser } from '@/src/components/application'
import { ApplicationBankConnectionUrlsV2 } from '@/src/constants/onboarding'
import { NullOrUndefined } from '@/types'

export const isStepV2Completed = (step: OnboardingStepV2, application: ApplicationV2 | NullOrUndefined): boolean =>
  application?.data?.completedSteps?.includes(step) ?? false

export const applicationRelevantPersonToApplicationUser = (
  relevantPerson: Partial<ApplicationRelevantPerson & { id?: string }>
): ApplicationUser => {
  const { address, ...rest } = relevantPerson
  const participation = (rest.participation as PersonParticipation[]) ?? []
  return {
    id: relevantPerson.id ?? cuid(),
    address: {
      address1: address?.address1 ?? '',
      address2: address?.address2 ?? '',
      city: address?.city ?? '',
      country: address?.country ?? '',
      state: address?.state ?? '',
      postalCode: address?.postalCode ?? '',
    },
    citizenship: rest.citizenship ?? '',
    dateOfBirth: rest.dateOfBirth ?? '',
    email: rest.email ?? '',
    firstName: rest.firstName ?? '',
    lastName: rest.lastName ?? '',
    phoneNumber: rest.phoneNumber ?? '',
    politicalExposure: Boolean(rest.politicalExposure) ? 'yes' : 'no',
    role: rest.participation?.join(' & ') ?? '',
    socialInsuranceNumber: rest.socialInsuranceNumber ?? '',
    participation,
    roleDescription: rest.roleDescription ?? undefined,
  }
}

export const applicationUserToApplicationRelevantPerson = (
  user: Partial<ApplicationUser>,
  removeApplicantParticipation?: boolean
): ApplicationRelevantPerson => ({
  address: {
    address1: user?.address?.address1,
    address2: user?.address?.address2,
    city: user?.address?.city,
    country: user?.address?.country,
    state: user?.address?.state,
    postalCode: user?.address?.postalCode,
  },
  citizenship: user.citizenship,
  dateOfBirth: user.dateOfBirth,
  email: user.email,
  firstName: user?.firstName ?? '',
  lastName: user?.lastName ?? '',
  socialInsuranceNumber: user.socialInsuranceNumber,
  phoneNumber: user.phoneNumber,
  politicalExposure: user.politicalExposure === 'yes',
  participation: Boolean(removeApplicantParticipation)
    ? user.participation?.filter((p) => p !== PersonParticipation.Applicant)
    : user.participation,
  roleDescription: user.roleDescription,
})

export const mapApplicationRelevantPeople = (user: Partial<ApplicationRelevantPerson>) => {
  const { address, ...rest } = user

  return {
    address: {
      address1: address?.address1,
      address2: address?.address2,
      city: address?.city,
      country: address?.country,
      state: address?.state,
      postalCode: address?.postalCode,
    },
    citizenship: rest.citizenship,
    dateOfBirth: rest.dateOfBirth,
    email: rest.email,
    firstName: rest.firstName,
    lastName: rest.lastName,
    socialInsuranceNumber: rest.socialInsuranceNumber,
    phoneNumber: rest.phoneNumber,
    politicalExposure: rest.politicalExposure,
    participation: rest.participation,
    roleDescription: rest.roleDescription,
  }
}

export const getBankConnectionRedirect = async (plaidItems: Array<PlaidItem> | undefined) => {
  const validPlaidItem = (plaidItems ?? []).find((item) => item.isValidToken)
  if (isDefined(validPlaidItem)) {
    return {
      permanent: true,
      destination: ApplicationBankConnectionUrlsV2[validPlaidItem.provider],
    }
  }
}

export const hasCompletedBankConnection = (plaidItems: Array<PlaidItem>, connectionType: BankingDataProvider) => {
  const validPlaidItem = (plaidItems ?? []).find((item) => item.isValidToken)
  return isDefined(validPlaidItem) && validPlaidItem.provider === connectionType
}
