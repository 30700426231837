import { AgreementTypeEnum } from 'db-schema'

/**
 * Describes the agreements that the user must accept.
 */
export type AgreementToAccept = {
  /**
   * The name of the agreement. This is what's gonna be displayed to the user.
   */
  name: string

  /**
   * The type of the agreement. This is what's gonna be used to show or hide the agreements according to the ones that the user already accepted
   */
  type: AgreementTypeEnum

  /**
   * The URL to the agreement. Users will be redirected to this URL when they
   * click on the agreement name.
   */
  url: string
}

/**
 * Describes the agreements that the user has pending.
 */
export type PendingAgreement = {
  id: string
  agreementType: string
  version: string
}

export const cardholderAgreement: AgreementToAccept = {
  name: 'Cardholder Agreement',
  url: 'https://www.trykeep.com/legal/cha',
  type: 'CARDHOLDER_AGREEMENT',
}

export const platformAgreement: AgreementToAccept = {
  name: 'Keep Platform Agreement',
  url: 'https://www.trykeep.com/legal/platform-agreement',
  type: 'PLATFORM_AGREEMENT',
}

export const referralTerms: AgreementToAccept = {
  name: 'Keep Referral Program Terms',
  url: 'https://uploads-ssl.webflow.com/62558bba1b2296492e8659f1/6658e39ee5ea91ec944dad73_Keep%20Referral%20Program%20-%202024-05-30.docx.pdf',
  type: 'REFERRAL_TERMS',
}

export const linkedAccountsAgreement: AgreementToAccept = {
  name: 'Linked Accounts Agreement',
  url: 'https://www.trykeep.com/legal/linked-accounts',
  type: 'LINKED_ACCOUNTS_AGREEMENT',
}

export const flinksEndUserAgreement: AgreementToAccept = {
  name: 'Flinks End User Agreement',
  url: 'https://www.trykeep.com/legal/flinks-end-user-agreement',
  type: 'FLINKS_END_USER_AGREEMENT',
}

export const privacyPolicyAgreement: AgreementToAccept = {
  name: 'Privacy Policy',
  url: 'https://www.trykeep.com/legal/privacy-policy',
  type: 'PRIVACY_POLICY',
}

export const websiteTermsAgreement: AgreementToAccept = {
  name: 'Website Terms and Conditions of Use',
  url: 'https://www.trykeep.com/legal/website-terms',
  type: 'TERMS_AND_CONDITIONS_OF_USE',
}

/**
 * The list of agreements that the user must accept in this modal.
 */
export const agreements: AgreementToAccept[] = [
  cardholderAgreement,
  platformAgreement,
  linkedAccountsAgreement,
  flinksEndUserAgreement,
  privacyPolicyAgreement,
  websiteTermsAgreement,
  {
    name: 'Business Accounts Agreement',
    url: 'https://www.trykeep.com/legal/business-accounts',
    type: 'BUSINESS_ACCOUNTS_AGREEMENT',
  },
  {
    name: 'Statement Advance Agreement',
    url: 'https://www.trykeep.com/legal/statement-advance',
    type: 'STATEMENT_ADVANCE_AGREEMENT',
  },
  {
    name: 'Rewards Terms',
    url: 'https://www.trykeep.com/legal/rewards-terms',
    type: 'REWARDS_TERMS',
  },
  {
    name: 'Electronic Communication Consent Agreement',
    url: 'https://www.trykeep.com/legal/electronic-consent',
    type: 'ELECTRONIC_COMMUNICATION_CONSENT_AGREEMENT',
  },
  {
    name: 'Prohibited and Restricted Activities',
    url: 'https://www.trykeep.com/legal/prohibited-and-restricted-activities',
    type: 'PROHIBITED_AND_RESTRICTED_ACTIVITIES',
  },
  {
    name: 'Pre-Authorized Debit Agreement',
    url: 'https://www.trykeep.com/legal/pad',
    type: 'PRE_AUTHORIZED_DEBIT_AGREEMENT',
  },
]
