import { isDefined, isValidAddress, isValidPostalCode } from 'utils'
import { z } from 'zod'

export const NOT_JUST_EMPTY_SPACE_REGEX = /[^\s]/

export const CAD_PHONE_REGEX = /^\+1\d{10}$/
export const PHONE_REGEX = /^\+\d{9,15}$/

export const addressSchema = z
  .object({
    address1: z.string().min(1, 'Address is required').refine(isValidAddress, 'PO box addresses are not valid'),
    address2: z.string().refine(isValidAddress, 'PO box addresses are not valid'),
    postalCode: z.string().min(1, 'Postal code is required'),
    state: z
      .string({ invalid_type_error: 'State is required' })
      .regex(NOT_JUST_EMPTY_SPACE_REGEX, {
        message: 'State is required',
      })
      .refine((value) => isDefined(value) && value?.length > 0, {
        message: 'State is required',
        path: ['value'],
      }),
    city: z
      .string({ invalid_type_error: 'City is required' })
      .regex(NOT_JUST_EMPTY_SPACE_REGEX, {
        message: 'City is required',
      })
      .refine((value) => isDefined(value) && value?.length > 0, {
        message: 'City is required',
        path: ['value'],
      }),
    country: z.string().refine((value) => isDefined(value) && value?.length > 0, {
      message: 'Country is required',
      path: ['value'],
    }),
  })
  .superRefine((value, ctx) => {
    if (value.country === 'CAN' && !isValidPostalCode(value.country, value.postalCode)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Invalid postal code (e.g. A2C 1B2)',
        path: ['postalCode'],
        fatal: true,
      })
    }
  })
