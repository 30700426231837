const unsupportedWebViewRegex = /(FB_IAB\/FB4A)|(FBAN\/FBIOS)/gi
const iosWebViewRegex = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/gi

export function isUnsupportedWebView(): boolean {
  if (typeof window === 'undefined') {
    return false
  }
  const agent = navigator.userAgent
  return Boolean(agent.match(unsupportedWebViewRegex)) || Boolean(agent.match(iosWebViewRegex))
}
