import { AbsoluteCenter, Box, Divider as ChakraDivider } from '@chakra-ui/react'
import React from 'react'

type TextDividerProps = React.ComponentProps<typeof ChakraDivider> & {
  content?: string
}

export const TextDivider = ({ content, ...props }: TextDividerProps) => {
  return (
    <Box position="relative" my={6}>
      <ChakraDivider {...props} />
      <AbsoluteCenter bg="white" px={4} textStyle="subheading-sm" color="text-soft">
        {content}
      </AbsoluteCenter>
    </Box>
  )
}
