import { Button, ButtonProps } from 'ui-lib'

type AuthFormButtonProps = Omit<ButtonProps, 'bg' | 'color'> & {
  dark?: boolean
}

const AuthFormButton = ({ dark = false, ...props }: AuthFormButtonProps) => {
  return (
    <Button
      bg={dark ? 'black.300' : 'white'}
      borderRadius="16px"
      height="48px"
      width="100%"
      color={dark ? 'white' : 'black.300'}
      _hover={{
        opacity: 0.8,
      }}
      _active={{
        opacity: 0.6,
      }}
      {...props}
    />
  )
}
export default AuthFormButton
