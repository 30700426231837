import { Box } from 'ui-lib'
import { isDefined } from 'utils'

type BoxPosition = 'left' | 'right' | 'bottom'

type CardAdornmentsProps = {
  exclude?: BoxPosition
}

export const CardAdornments = ({ exclude }: CardAdornmentsProps) => {
  const box1 = (
    <Box
      height="133.26px"
      width="133.26px "
      key="1"
      background="linear-gradient(to bottom left, #e28f43, #f15379)"
      opacity="0.7"
      borderRadius="30px"
      transform={'rotate(60deg)'}
      position="absolute"
      style={{
        top: '40px',
        right: '-110px',
      }}
    />
  )
  const box2 = (
    <Box
      height="133.26px"
      width="133.26px "
      key="2"
      background="linear-gradient(235.58deg, #DB57B6 0%, #6497C7 100%)"
      opacity="0.7"
      borderRadius="30px"
      transform={'rotate(145deg)'}
      position="absolute"
      style={{
        top: '-30px',
        left: '-70px',
      }}
    />
  )
  const box3 = (
    <Box
      height="133.26px"
      key="3"
      width="133.26px"
      background="linear-gradient(213.67deg, #B287E7 -36.26%, #CA65D5 27.32%, #ED9267 115.25%)"
      opacity="0.7"
      borderRadius="30px"
      transform={'rotate(145deg)'}
      position="absolute"
      style={{
        bottom: '-110px',
        right: '50px',
      }}
    />
  )
  const boxes = [box1, box2, box3]
  const excludeMap = {
    left: 0,
    right: 1,
    bottom: 2,
  }
  return <>{boxes.filter((b, i) => (isDefined(exclude) ? excludeMap[exclude] !== i : true))}</>
}
