import { Flex, useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'

type HeaderProps = React.ComponentProps<typeof Flex>

export const Header = (props: HeaderProps) => {
  const styles = useMultiStyleConfig('Header')
  return (
    <Flex as="header" {...styles} {...props}>
      {props.children}
    </Flex>
  )
}
