import React from 'react'
import { ButtonProps, chakra, Text } from 'ui-lib'

import AuthFormButton from '@/src/components/atoms/AuthFormButton'
import GoogleIcon from '@/src/svgs/googleIcon.svg'

const ContinueWithGoogle = (props: ButtonProps) => {
  return (
    <AuthFormButton {...props}>
      <chakra.img src={GoogleIcon.src} />
      <Text fontSize="20px" fontWeight="600" marginLeft="8px" color="#181C20">
        Continue with Google
      </Text>
    </AuthFormButton>
  )
}

export default ContinueWithGoogle
