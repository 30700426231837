import { setCookie } from 'cookies-next'
import getConfig from 'next/config'

import { KeepCookie } from '../constants/cookies'
import { urlHasAttributionData } from '../utils/urlHasAttributionData'
import { useEffectOnce } from './misc'
const { publicRuntimeConfig } = getConfig()

const useAttributionCookie = () => {
  useEffectOnce(() => {
    if (urlHasAttributionData(window.location.search)) {
      const isLocal = !Boolean(publicRuntimeConfig?.webUrl.includes('trykeep.com'))
      setCookie(KeepCookie.ANALYTICS_UTM, window.location.search, {
        domain: isLocal ? '127.0.0.1' : 'trykeep.com', // open web in 127.0.0.1 for local development
        sameSite: isLocal ? undefined : 'strict',
        maxAge: 1707109200, // 400 days
        secure: !isLocal,
      })
    }
  })
}

export default useAttributionCookie
