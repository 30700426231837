import { BankingDataProvider } from '@prisma/client'
import { OnboardingStep } from 'db-schema'
import { ConstToUnion } from 'utils/types'

import { OnboardingStepV2 } from '@/gql'

export const WizardSteps = {
  PERSONAL_INFORMATION: 'Personal information',
  BUSINESS_INFORMATION: 'Business information',
  BANK_CONNECTION: 'Bank connection',
  IDENTITY_VERIFICATION: 'Identity verification',
  REVIEW: 'Review',
} as const
export type WizardSteps = ConstToUnion<typeof WizardSteps>
export const WizardTitles = Object.values(WizardSteps)

export const OnboardingWizardActiveStep: Partial<Record<OnboardingStep, WizardSteps>> = {
  [OnboardingStep.INTRODUCTION_0]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.DISCLOSURE_00]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.PERSONAL_INFORMATION_1]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.ADDITIONAL_INFORMATION_2]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.PERSONAL_ADDRESS_3]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.RELATIONSHIP_TO_THE_BUSINESS_40]: WizardSteps.PERSONAL_INFORMATION,
  [OnboardingStep.ABOUT_BUSINESS_4]: WizardSteps.BUSINESS_INFORMATION,
  [OnboardingStep.BUSINESS_ADDRESS_5]: WizardSteps.BUSINESS_INFORMATION,
  [OnboardingStep.BUSINESS_OWNERS_6]: WizardSteps.BUSINESS_INFORMATION,
  [OnboardingStep.BUSINESS_DIRECTORS_7]: WizardSteps.BUSINESS_INFORMATION,
  [OnboardingStep.INCORPORATION_DOCUMENTS_8]: WizardSteps.BUSINESS_INFORMATION,
  [OnboardingStep.PLAID_9]: WizardSteps.BANK_CONNECTION,
  [OnboardingStep.IDENTITY_VERIFICATION_10]: WizardSteps.IDENTITY_VERIFICATION,
  [OnboardingStep.REVIEW_11]: WizardSteps.REVIEW,
}

export const applicationUrls: Partial<Record<OnboardingStep, string>> = {
  [OnboardingStep.INTRODUCTION_0]: '/application/introduction',
  [OnboardingStep.DISCLOSURE_00]: '/application/disclosure',
  [OnboardingStep.PERSONAL_INFORMATION_1]: '/application/personal-information',
  [OnboardingStep.ADDITIONAL_INFORMATION_2]: '/application/additional-information',
  [OnboardingStep.PERSONAL_ADDRESS_3]: '/application/personal-address',
  [OnboardingStep.RELATIONSHIP_TO_THE_BUSINESS_40]: '/application/relationship-to-the-business',
  [OnboardingStep.ABOUT_BUSINESS_4]: '/application/about-business',
  [OnboardingStep.BUSINESS_ADDRESS_5]: '/application/business-address',
  [OnboardingStep.BUSINESS_OWNERS_6]: '/application/business-owners',
  [OnboardingStep.BUSINESS_DIRECTORS_7]: '/application/business-directors',
  [OnboardingStep.INCORPORATION_DOCUMENTS_8]: '/application/incorporation-documents',
  [OnboardingStep.PLAID_9]: '/application/bank-connection',
  [OnboardingStep.IDENTITY_VERIFICATION_10]: '/application/identity-verification',
  [OnboardingStep.REVIEW_11]: '/application/review',
}

export const MISSING_INFORMATION_URL = '/application/v2/missing-information'

export const PHONE_VERIFICATION_URL = '/application/v2/phone-verification'

export const INTRODUCTION_V2_URL = '/application/v2/introduction'

export const ApplicationUrlsV2: Record<OnboardingStepV2, string> = {
  PRODUCTS_SELECTION: '/application/v2/products-selection',
  CARD_SETUP_INTRO: '/application/v2/card-introduction',
  CARD_SETUP: '/application/v2/card-setup',
  BUSINESS_DETAILS: '/application/v2/business-details',
  INCORPORATION_DETAILS: '/application/v2/incorporation-details',
  BUSINESS_ADDRESS: '/application/v2/business-address',
  LIST_OF_OWNERS: '/application/v2/list-of-owners',
  APPLICANT_IDENTITY: '/application/v2/applicant-identity',
  IDENTITY_VERIFICATION: '/application/v2/identity-verification',
  FINANCIAL_DETAILS: '/application/v2/bank-connection',
  REVIEW: '/application/v2/review',
}

export const PRICING_INTRODUCTION_LIST = [
  { main: '$0 Domestic Transaction Fee' },
  { main: '$0 Annual Fee' },
  { main: '$0 Per Card Fee' },
  { main: '$0 Maintenance Fee' },
  { main: 'Rewards', note: 'Up to 4X points for certain categories' },
  {
    main: 'Balance Transfers',
    note: 'Keep does not support balance transfers',
  },
  {
    main: 'Foreign Currency Conversion Fee',
    note: 'Up to 3% and rebated with 4X points',
  },
  { main: '$0 Reissue Card Fee' },
]

export const ONBOARDING_INTRODUCTION_LIST = [
  {
    icon: 'shield-check',
    content:
      'In order to qualify for the Keep Corporate Credit Mastercard®, we need to verify your identity, as required by applicable laws.',
  },
  {
    icon: 'fingerprint',
    content:
      'You acknowledge, consent, and authorize Keep to use the information to validate your identity. Your data is secure.',
  },
  {
    icon: 'lock',
    content: 'We collect your private information, such as, name, address, date of birth.',
  },
]

export const ApplicationBankConnectionUrlsV2: Partial<Record<BankingDataProvider, string>> = {
  [BankingDataProvider.FLINKS]: '/application/v2/bank-connection',
  [BankingDataProvider.MANUAL]: '/application/v2/manual-connection',
}
