import { isDefined } from './chunk-GVNWCKLH.mjs';

// src/constants/application.ts
var numberOfEmployeesLimits = {
  tier1: {
    min: 1,
    max: 10
  },
  tier2: {
    min: 11,
    max: 20
  },
  tier3: {
    min: 21,
    max: 50
  },
  tier4: {
    min: 51,
    max: 100
  },
  tier5: {
    min: 101,
    max: null
  }
};
var NUMBER_OF_EMPLOYEES_LIMITS_OPTIONS = Object.keys(numberOfEmployeesLimits).map((key) => {
  const min = numberOfEmployeesLimits[key].min;
  const max = numberOfEmployeesLimits[key].max;
  const label = `${min}${isDefined(max) ? ` - ${max}` : `+`}`;
  return { label, value: key };
});
var getNumberOfEmployeesLimit = (min) => {
  if (!isDefined(min)) {
    return { tier: null, min: null, max: null };
  }
  const limit = Object.entries(numberOfEmployeesLimits).find(([, { min: limitMin }]) => min === limitMin);
  if (!isDefined(limit)) {
    return { tier: null, min: null, max: null };
  }
  return {
    tier: limit[0],
    min: limit[1].min,
    max: limit[1].max
  };
};

export { NUMBER_OF_EMPLOYEES_LIMITS_OPTIONS, getNumberOfEmployeesLimit, numberOfEmployeesLimits };
