import getConfig from 'next/config'
import { isDefined } from 'ui-lib'

import { NextConfig } from '@/types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig

export function getRedirectUrl(redirectQuery: string) {
  if (!isDefined(redirectQuery)) {
    return null
  }

  const allowedClientRedirects = publicRuntimeConfig.allowedClients
  const isLocalUrl = redirectQuery?.startsWith('/') ?? false
  const isAllowedHost = allowedClientRedirects.some((host) => redirectQuery.includes(host))
  const canRedirect = isLocalUrl || isAllowedHost

  return canRedirect ? redirectQuery : null
}
