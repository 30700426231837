/**
 * Function to check if a url has any marketing parameters
 * @param url URL to check
 * @returns Boolean indicating if the url has marketing parameters
 */
export const urlHasAttributionData = (url: string): boolean =>
  url.includes('utm_source') ||
  url.includes('gclid') ||
  url.includes('fbclid') ||
  url.includes('ttclid') ||
  url.includes('twclid')
